import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import {Link} from 'react-router-dom'

export default function About() {
  return (
    <>
      <Tabs
        defaultActiveKey="Hebrew"
        id="uncontrolled-tab-example"
        className="mb-3 rlt"
      >
        <Tab eventKey="Hebrew" title="עברית">
          <div className="text-center rlt">
            <h1>שותפים במהפכה</h1>
            <div className="pb-5">
              <p>
                בית חב"ד החדש שנפתח בעיר העתיקה בבאר שבע, מהווה אבן שואבת לכל
                עובר ושב.
              </p>
              <p>המקום שזכה לתואר "בית חב"ד הכי יפה בעולם"</p>
              <br />
              <p>
                במקום פועל בית התבשיל שמחלק מצרכים ומנות חמות למאות משפחות לפני
                שבתות וחגים.
              </p>
              <p>
                כמו כן הסופר החברתי בשיתוף עמותת אנשים שנפתח במקום ומארגן שלוש
                פעמים בשבוע מכירה של מצרכים למשפחות הנזקקות במחירים סמליים.
              </p>
              <p>
                לבית חב"ד מגיעים כל הזמן תושבי העיר ותיירים וכן העולים החדשים
                ומבקשים ברכה של הרבי שליט"א מלך המשיח באמצעות האגרות קודש ורואים
                ניסים גלויים
              </p>
              <p>כל זה בנוסף לסניף בנווה זאב שממשיך לפרוח ולשגשג.</p>
              <p>
                כעת אנחנו פונים אליך לקחת שותפות וחלק במהפכה הגדולה שמתחוללת כאן
                בבאר שבע
              </p>
              <p>יחי אדוננו מורנו ורבינו מלך המשיח לעולם ועד</p>

              <br />
              <div>
                <p>לתרומה בהעברה בנקאית:</p>
                <p>בנק מרכנתיל סניף 645</p>
                <p>מספר חשבון 67079</p>
                <p>ע"ש מרכז ההתגלות באר שבע (ע"ר)</p>
              </div>
              <br />
              <p>לפרטים נוספים 0587490770</p>
              <a href="mailto:mercazhaitgalut@gmail.com">
                mercazhaitgalut@gmail.com
              </a>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Russian" title="Russian">
          <div className="mb-5 noRlt">
            <h1>Дорогие друзья!</h1>
            <p>
              {" "}
              Благодаря вашей помощи в кампании год назад, в Беэр-Шеве
              происходит настоящая революция во всем, что касается вопросов Торы
              и заповедей, взаимопомощи и еврейской жизни, и главное, конечно
              же, во всем, что связано с темой Мошиаха и Освобождения.
            </p>
            <p>
              Новый центр русскоязычного ХАБАД-Любавичского центра{" "}
              <b>«Итгалут»</b>, открывшийся в Старом городе Беэр-Шевы, не дает
              никому оставаться равнодушным. Любой, кто посещает Старый город,
              не может не заметить и не восхититься впечатляющей красотой этого
              места, которое даже было удостоено звания «самого красивого центра
              ХАБАДа в мире».
            </p>
            <p>
              В этом месте жизнь кипит с раннего утра и до позднего вечера.
              Постоянно заходят жители города или туристы, старожилы и новые
              репатрианты. В этом месте проходят общественные молитвы и уроки
              Торы, проверяют тфиллин и мезузы и, конечно, обращаются к
              Любавичскому Ребе ШЛИТА Королю Мошиаху за просьбой к благословения
              и советом через «Игрот Кодеш».
            </p>
            <p>
              Особое внимание уделяется оказанию материальной и духовной помощи
              русскоязычному населению и в особенности новым репатриантам,
              приехавшим в Израиль в последнее время. Перед праздниками и
              историческим датами в нашем центре организовывают уроки и встречи
              с известными русскоязычными раввинами и лекторами, благодаря чему
              новые репатрианты знакомятся с основами иудаизма и хасидизма.
            </p>
            <p>
              Многие жители города уже знают, что бар-мицва, обрезание, выкуп
              первенца и т.д. должны совершаться в месте, сочетающем красоту с
              особой атмосферой святости. Здесь можно также получить подробную
              инструкцию для тех, кто это делает впервые, что особенно важно для
              новых репатриантов.
            </p>
            <p>
              Ребе ШЛИТА Король Мошиах учит нас, что самое главное — помочь
              еврею в первую очередь в материальном смысле. Тяжелое
              экономическое состояние многих жителей города, особенно новых
              репатриантов, заставило руководство Центра «Итгалут» принять
              смелое решение:
              <b>
                {" "}
                мы открываем центр взаимопомощи и перед каждой субботой и
                праздниками будем раздавать продукты сотням семей. Кроме того, в
                сотрудничестве с организацией «Анашим», в нашем центре открылся
                специальный супермаркет, в котором нуждающиеся могут купить
                продукты по номинальным ценам.
              </b>
            </p>
            <p>
              Благодаря праведным женщинам наши праотцы были освобождены из
              Египта и благодаря ним мы удостоится будущего Освобождения…
            </p>
            <p>
              В центре ХАБАДа в Старом городе проводится обширная деятельность
              для женщин, которая включает в себя уроки по теме Мошиаха и
              Освобождения, законам чистоты семейной жизни и недельной главы
              Торы. Кроме этого, праздничное женское застолье происходит каждый
              месяц в честь новомесячья, а также в честь еврейских праздников и
              хасидских дат.
            </p>
            <p>
              Наряду с деятельностью в новом филиале в Старом городе, Центр
              «Итгалут» в районе <b>Неве-Зеэв</b> постоянно расширяет свою
              работу. Сформировавшаяся хасидская община за последний год
              удвоилась и даже утроилась; по субботам, праздникам и будням
              проходят молитвы, уроки Торы на самых разных языках (иврит,
              русский, испанский и др.).
            </p>
            <p>
              В дополнение к постоянной деятельности, проводятся также
              специальные сезонные мероприятия, например зажигания ханукальных
              свечей в нескольких центральных местах в районе, а также раздача
              ханукальных наборов жителям. Во время праздника Пурим активисты
              круглосуточно проводят чтение свитка Эстер и раздают тысячи
              посылок с праздничными подарками. Центр «Итгалут» организовывает
              общественный пасхальный седер, в котором принимают участие сотни
              жителей Беэр-Шевы, старожилы и новые репатрианты.
            </p>
            <p>
              Есть также занятия для женщин и специальные мероприятия для детей.
              Так, например, детский парад на Лаг ба-Омер в районе Неве-Зеэв уже
              стал традицией, и дети района с нетерпением ждут этого особого
              события в течение всего года…
            </p>
            <p>
              Ребе ШЛИТА Король Мошиах учит нас, что нельзя удовлетворяться
              достигнутым, а постоянно стремится многократно увеличить
              деятельность. Иными словами, мы все должны принять участие в
              положительной, мирной еврейской «революции», кульминацией которой
              без сомнения станет абсолютное раскрытие Ребе ШЛИТА Короля Мошиаха
              и наступления настоящего и полного Освобождения, немедленно прямо
              сейчас! И это революция произойдёт благодаря вам!
            </p>
            <p>
              <Link
                to={
                  "https://www.chabadbr7.com/64d8bd345321fb45e5561b56/itgalutlotkin"
                }
              >
                https://www.chabadbr7.com
              </Link>
            </p>
            <h5>
              ДА ЗДРАВСТВУЕТ ЛЮБАВИЧСКИЙ РЕБЕ КОРОЛЬ МОШИАХ ВО ВЕКИ ВЕКОВ!
            </h5>
          </div>
        </Tab>
      </Tabs>
    </>
  );
}
