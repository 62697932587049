import React, { createContext, useContext, useReducer, useEffect } from "react";
import axios from "axios";

const domain = `https://back-5zii.onrender.com/api`;
// const domain = `http://localhost:5000/api`;

// Initial state for the reducer
const initialState = {
  donors: [],
  loading: true,
  error: null,
  sumOfAllDonorAmount: 0,
  totalDonors: 0,
  offset: 0,
};

// Reducer function
const donorReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        donors: action.payload.donors,
        totalDonors: action.payload.totalCount,
        loading: false,
        error: null,
      };
    case "FETCH_SUCCESS_DONORS_BY_GROUP":
      return {
        ...state,
        donors: action.payload,
        loading: false,
        error: null,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "ADD_DONOR_SUCCESS":
      return {
        ...state,
        donors: [...state.donors, action.payload],
      };
    case "ADD_DONOR_ERROR":
      return state;
    case "FETCH_TOTAL_AMOUNT_SUCCESS":
      return {
        ...state,
        sumOfAllDonorAmount: action.payload,
      };
    case "FETCH_TOTAL_AMOUNT_ERROR":
      return state;
    default:
      return state;
  }
};

const DonorContext = createContext();

export const DonorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(donorReducer, initialState);

  const fetchSortedAndLimitedDonors = async (sortBy, sortOrder, pageNumber) => {
    try {
      console.log(pageNumber, sortBy, sortOrder);
      const response = await axios.get(`${domain}/donor`, {
        params: { sortBy, sortOrder, pageNumber },
      });
      dispatch({ type: "FETCH_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: error.message });
    }
  };

  const getDonorsByGroupId = async (groupId) => {
    try {
      const response = await axios.get(`${domain}/donor/groupId/${groupId}`);
      dispatch({
        type: "FETCH_SUCCESS_DONORS_BY_GROUP",
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: error.message });
    }
  };

  const addDonor = async (newDonor) => {
    try {
      const response = await axios.post(`${domain}/donor`, newDonor);
      dispatch({ type: "ADD_DONOR_SUCCESS", payload: response.data });
      fetchSortedAndLimitedDonors();
      //   fetchData();
    } catch (error) {
      dispatch({ type: "ADD_DONOR_ERROR" });
    }
  };

  const fetchTotalDonorAmount = async () => {
    try {
      const response = await axios.get(`${domain}/donor/totalAmount`);
      dispatch({
        type: "FETCH_TOTAL_AMOUNT_SUCCESS",
        payload: response.data.totalAmount,
      });
    } catch (error) {
      dispatch({ type: "FETCH_TOTAL_AMOUNT_ERROR", payload: error.message });
    }
  };

  useEffect(() => {
    fetchTotalDonorAmount(); // Call the function here
  }, []);

  const updateDonor = async (donorId, updatedDonor) => {
    try {
      const response = await axios.put(
        `${domain}/donor/${donorId}`,
        updatedDonor
      );
      fetchSortedAndLimitedDonors();
      const updatedDonorIndex = state.donors.findIndex(
        (donor) => donor._id === donorId
      );
      if (updatedDonorIndex !== -1) {
        const updatedDonors = [...state.donors];
        updatedDonors[updatedDonorIndex] = response.data;
        dispatch({ type: "FETCH_SUCCESS", payload: updatedDonors });
      }
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: error.message });
    }
  };

  const deleteDonor = async (donorId) => {
    try {
      await axios.delete(`${domain}/donor/${donorId}`);
      const updatedDonors = state.donors.filter(
        (donor) => donor._id !== donorId
      );
      dispatch({ type: "FETCH_SUCCESS", payload: updatedDonors });
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: error.message });
    }
  };

    const fetchDonorsForExcel = async () => {
      try {
        const response = await axios.get(`${domain}/donor/excel`, {
          responseType: "blob",
        });
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "donors.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error fetching donors for Excel:", error);
        // Handle error if needed
      }
    };

  return (
    <DonorContext.Provider
      value={{
        ...state,
        addDonor,
        // fetchData,
        getDonorsByGroupId,
        fetchTotalDonorAmount,
        updateDonor,
        deleteDonor,
        fetchSortedAndLimitedDonors,
        fetchDonorsForExcel,
      }}
    >
      {children}
    </DonorContext.Provider>
  );
};

export const useDonorContext = () => {
  return useContext(DonorContext);
};
